import * as React from 'react';
import { Route, RouteComponentProps, withRouter, Switch, Redirect } from 'react-router';
import Layout from './components/Layout';
import Report from './components/Home';
import Export from './components/Export';
import Error from './components/layout/Error';
import Warning from './components/layout/Warning';
import Help from './components/help/Help';
import { myMSALObj, tokenRequest, loginRequest, authorities } from './store/authConfig';
import { AuthResponse } from 'msal';
import { AuthenticatedUser, convertStringDict } from './model/AuthenticatedUser';
import { CustomError } from './model/Error';
import { State } from './store/rootReducer';
import { bindActionCreators } from 'redux';
import { setCurrentUserAction } from './store/actions/userActions';
import { connect } from 'react-redux';
import { apiReports, apiPages, apiFirmDropDown, apiPrintMenuItems } from './api/Api';
import { setAllPagesAction } from './store/actions/pageActions';
import { setIsLoadingAction } from './store/actions/loadingActions';
import { Page } from 'powerbi-client';
import { EmbedResult } from './model/EmbedResult';
import { setReportAction } from './store/actions/reportActions';
import { setAllFirmsAction } from './store/actions/firmActions';
import { setAllErrorAction } from './store/actions/errorActions';
import { setAuthenticatedUserForTelemetry } from './common/TelemetryService';
import CircularProgress from '@mui/material/CircularProgress';
import './custom.css';
import { setAllPrintMenuItemsAction } from './store/actions/printMenuItemsActions';

let timeoutWarning: NodeJS.Timeout;

// Timeout & wanrning values in minutes
const warnAfter = 10;
const timeoutAfter = 15;

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

interface IAppState {
    isLoggedIn: boolean;
    homeReportName: string;
    homeReportGroup: string;
    majorError: boolean;
    errorMsg?: string;
    seconds: number;
    timerCalled: boolean;
    isLoading: boolean;
    showWarning: boolean;
    noreload: boolean;
}

interface IAppStoreProps {
    currentlyAuthenticatedUser: AuthenticatedUser,
    pages: Array<Page>,
    siteError: CustomError;
}

// Dispatch to redux
interface IAppDispatchProps {
    setCurrentUser: (authUser: AuthenticatedUser) => void;
    setPages: (pages: any) => void;
    setLoading: (isLoading: any) => void;
    setPrintMenuItems: (items: Array<any>) => void;
    setReport: (report: EmbedResult[]) => void;
    setFirms: (firms: any) => void;
    setError: (error: CustomError) => void;
}

class App extends React.Component<RouteComponentProps<any> & IAppDispatchProps & IAppStoreProps, IAppState> {

    public constructor(props: any) {
        super(props);
        this.state = {
            isLoggedIn: false,
            homeReportName: '',
            homeReportGroup: '',
            seconds: 10,
            timerCalled: false,
            majorError: false,
            errorMsg: undefined,
            isLoading: true,
            showWarning: false,
            noreload: false
        };
    }

    async componentDidMount() {
        myMSALObj.handleRedirectCallback(this.authCallback);

        if (myMSALObj.getAccount() === null) {
            console.log(myMSALObj.getAccount());
            myMSALObj.loginRedirect(loginRequest);
        } else {
            if (!this.props.currentlyAuthenticatedUser.id) {

                var user = convertStringDict(myMSALObj.getAccount().idToken);
                this.props.setCurrentUser(user);
            }
            this.setState({
                isLoggedIn: true
            });
            myMSALObj.acquireTokenSilent(tokenRequest)
                .then((tokenResponse: AuthResponse) => {
                    this.loadReportData(tokenResponse.accessToken);
                    setAuthenticatedUserForTelemetry(this.props.currentlyAuthenticatedUser);
                })
                .then(() => { this.setState({ isLoading: false }); })
                .catch((error: any) => {
                    //this.setState({ isLoading: false, majorError: true, errorMsg: "Authentication failed." });
                    return myMSALObj.acquireTokenRedirect(tokenRequest);
                });

            if (!this.state.showWarning) {
                timeoutWarning = setTimeout(this.warn, warnAfter * 60000);
            }

            for (var i in events) {
                window.addEventListener(events[i], this.resetTimeout);
            }
        }
    }

    public componentWillUnmount() {
        clearTimeout(timeoutWarning);
    }

    render() {
        document.title = "ALAS Data Insights"
        if (this.state.isLoading || !this.state.isLoggedIn) {
            return (<div style={{ margin: '50vh 50%', color: '#02568A' }}><CircularProgress /></div>);
        }

        if (this.state.isLoggedIn && !this.props.currentlyAuthenticatedUser.isAuthorized()) {
            return (
                <>
                    {!this.state.isLoading &&
                        <Error show={true} errorMsg={"Unauthorized access."} />
                    }
                </>
            );
        }
        else {
            return (
                <>
                    {!this.state.isLoading && <Layout>
                        <Error show={this.state.majorError} errorMsg={this.state.errorMsg} />
                        {this.state.showWarning && <Warning show={this.state.showWarning} minutes={timeoutAfter - warnAfter} close={this.close} />}
                        <Switch>
                            <Route exact path='/:reportGroup/' render={(props) => <Report {...props} currentReportGroup={this.state.homeReportGroup} currentPage={this.state.homeReportName} hideNav={this.props.currentlyAuthenticatedUser.isALAS()} noreload={this.state.noreload} />} />
                            <Route exact path='/:reportGroup/help' render={(props) => <Help {...props} />} />
                            <Route exact path='/:reportGroup/export' component={Export} />
                            {this.props.pages ? this.props.pages.map((page: any) => (
                                page.reportPages.map((reportPage: any) => (
                                    <Route exact path={this.getLink(reportPage.displayName)} key={reportPage.name} render={(props) => <Report {...props} currentReportGroup={page.reportGroup} currentPage={reportPage.name} hideNav={this.props.currentlyAuthenticatedUser.isALAS()} noreload={this.state.noreload} />} />
                                ))
                            )) : <></>}
                            {
                                (this.props.pages && this.props.pages.length > 0 && (this.props.pages[0] as any).reportPages && (this.props.pages[0] as any).reportPages.length > 0) ?
                                    <Route exact path='/'><Redirect to={`/${(this.props.pages[0] as any).reportGroup}/${(this.props.pages[0] as any).reportPages[0].displayName.replace(/\s+/g, '-').toLowerCase() }`} /></Route> :
                                    <Route exact path='/' render={(props) => <Report {...props} currentReportGroup={this.state.homeReportGroup} currentPage={this.state.homeReportName} hideNav={this.props.currentlyAuthenticatedUser.isALAS()} noreload={this.state.noreload} />} />
                            }
                        </Switch>
                    </Layout>}
                </>
            );
        }
    }

    public authCallback = (error: any, success: AuthResponse | undefined) => {
        if (error) {

            if (error.errorMessage.indexOf("AADB2C90118") > -1) {
                try {
                    // Password reset policy/authority
                    myMSALObj.loginRedirect(authorities.forgotPassword);
                } catch (err) { }
            }
        } else if (success) {
            // Login
            if (success.tokenType === "id_token") {
                if (!this.props.currentlyAuthenticatedUser.id) {

                    var user = convertStringDict(myMSALObj.getAccount().idToken);
                    this.props.setCurrentUser(user);
                }
            }
            // API 
            else if (success.tokenType === "access_token") {
            }
        }
    }

    private getLink = (pagename: string) => {
        return "/:reportGroup/" + pagename.replace(/\s+/g, '-').toLowerCase();
    }

    private loadReportData = (accessToken: string) => {

        apiReports(accessToken)
            .then((apiResp: any) => {

                if (apiResp.status === 401 || apiResp.status === 403) {
                    this.setState({ isLoading: false, majorError: true, errorMsg: "Unauthorized access." });
                }

                this.props.setReport(apiResp);
            })
            .catch((error) => {
                this.setState({ isLoading: false, majorError: true, errorMsg: undefined })
            });

        this.props.setLoading({ pages: true });
        apiPages(accessToken)
            .then((apiResp: any) => {
                if (apiResp !== null && apiResp.length > 0) {
                    // var homeReportName = window.innerWidth > 576 ? apiResp[0].reportPages[0].name : apiResp.pop()!.reportPages[0].name;
                    var homeReportName = apiResp[0].reportPages[0].name;
                    // var homeReportGroup = window.innerWidth > 576 ? apiResp[0].reportGroup : apiResp.pop()!.reportreportGroup;
                    var homeReportGroup = apiResp[0].reportGroup;
                    this.setState({
                        homeReportName,
                        homeReportGroup
                    });
                }
                this.props.setPages(apiResp);
                this.props.setLoading({ pages: false });
            })
            .catch((error) => {
                this.setState({ isLoading: false, majorError: true, errorMsg: undefined });
                this.props.setLoading({ pages: false });
            });

        apiPrintMenuItems(accessToken)
            .then((apiResp: Array<any>) => {
                this.props.setPrintMenuItems(apiResp);
            })
            .catch((error) => {
                this.setState({ isLoading: false, majorError: true, errorMsg: undefined });
            });
        if (this.props.currentlyAuthenticatedUser.isALAS()) {
            apiFirmDropDown(accessToken)
                .then((apiResp: any) => {
                    this.props.setFirms(apiResp);
                })
                .catch((error) => {
                    this.setState({ isLoading: false, majorError: true, errorMsg: undefined });
                }); 
        }
    }

    private resetTimeout = () => {
        clearTimeout(timeoutWarning);
        timeoutWarning = setTimeout(this.warn, warnAfter * 60000);
    }

    private warn = () => {
        this.setState({ showWarning: true });
    }

    private close = () => {
        this.setState({ showWarning: false, noreload: true });
    }
}

function mapStateToProps(state: State) {
    return {
        currentlyAuthenticatedUser: state.currentlyAuthenticatedUser,
        pages: state.pages,
        siteError: state.error
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setCurrentUser: setCurrentUserAction,
            setPages: setAllPagesAction,
            setLoading: setIsLoadingAction,
            setPrintMenuItems: setAllPrintMenuItemsAction,
            setReport: setReportAction,
            setFirms: setAllFirmsAction,
            setError: setAllErrorAction
        },
        dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));